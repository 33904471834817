import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
} from '@mui/material';
import logo from './logo2.png'; 

function ArticlesListPage() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const { groupName } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [goldenRatioWidth, setGoldenRatioWidth] = useState(null);
  const [goldenLineRatioWidth, setGoldencLineRatioWidth] = useState(null);
  const [WordgoldenRatioWidth, setWordGoldenRatioWidth] = useState(null);
  
  useEffect(() => {
    // 根据isMobile状态来更新goldenRatioWidth
    if (isMobile) {
      setWordGoldenRatioWidth(10);
      setGoldenRatioWidth(100);
      setGoldencLineRatioWidth(15);
    } else {
      setGoldenRatioWidth(100 * 0.85);
      setGoldencLineRatioWidth(10);
      setWordGoldenRatioWidth(14);
    }
  }, []); // 当isMobile变化时，重新运行此效果

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`https://wechat.haodaqi.net/api/articles-by-group/${encodeURIComponent(groupName)}/`)
        if (response.ok) {
          const data = await response.json();
          setArticles(data); // 更新文章列表数据
        } else {
          console.error('获取文章列表失败');
        }
      } catch (error) {
        console.error('获取文章列表请求出错', error);
      }
    };

    fetchArticles();

    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    setIsLoggedIn(!!token);
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []); // 传入空数组确保 useEffect 只在组件挂载时执行一次

  const handleLogout = (event) => {
    event.preventDefault();  // 阻止链接默认行为
    event.stopPropagation(); // 阻止事件冒泡
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
  };

  const handleProtectedLinkClick = (e, path) => {
    if (!isLoggedIn) {
      e.preventDefault(); // 阻止默认链接行为
      navigate('/login'); // 导航到登录页面
    } else {
      navigate(path); // 导航到指定的路径
    }
  };
  
  
  const handleArticleClick = (articleId) => {
    navigate(`/articledetail/${articleId}`);
  };


  return (
      <div 
      style={{
        marginTop:'10px',
        display: 'flex',
        flexDirection: 'column', // 垂直方向排列
        justifyContent: 'center', // 水平居中
        alignItems: 'center', // 垂直居中

      }}> 
      <div
          style={{
            backgroundColor: '#ffa343', // 
            color: 'white', // 文字颜色为白色
            width: `${goldenRatioWidth}vw`,
            display: 'flex', // 使用flex布局使图标和文字在一行显示
            alignItems: 'center', // 垂直居中对齐
            height:'26px'
      }}
      >
        <img src={logo} alt="Logo" width="18px" height="18px" style={{marginLeft:'3px',border: '1px solid black'}}/> 
        <a href="/" style={{ color: 'black', fontSize:  `${WordgoldenRatioWidth}px`, marginLeft: '5px', fontWeight: 'bold', fontFamily: 'sans-serif', textDecoration: 'none'}}>好端端</a>
        <a href="/DifferentChart" 
          style={{ fontSize: `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/DifferentChart')}>
          项目
        </a>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black' }}>|</span>
        {isLoggedIn && (
           <a href="/dashboard" style={{ fontSize:`${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`,color: 'black', textDecoration: 'none' }}>我的</a>
        )}
        {isLoggedIn && (
           <span style={{ fontSize: `${WordgoldenRatioWidth}px`, color: 'black' }}>|</span>
        )}
        <a href="/chatgpt" 
          style={{ fontSize:  `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/chatgpt')}>
          AI
        </a>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`,color: 'black' }}>|</span>
        <a href="/new-article" 
          style={{ fontSize:  `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/new-article')}>
          发布
        </a>
        <div style={{ display: 'flex', alignItems: 'center' ,marginLeft: 'auto',}}>
          {isLoggedIn && (
            <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black', marginRight: `${WordgoldenRatioWidth*0.5}px` }}>
              {username}
            </span>
          )}
          {isLoggedIn && (
          <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black' ,marginRight: `${WordgoldenRatioWidth*0.5}px`}}>|</span>
          )}
          <a href={isLoggedIn ? "/" : "/login"} 
            style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black', marginRight:'3px'}}
            onClick={isLoggedIn ? handleLogout : null}>
            {isLoggedIn ? 'logout' : 'login'}
          </a>
        </div>
      </div>


      <div style={{ 
        width: `${goldenRatioWidth}vw`, // 使用黄金比例的宽度
        minHeight: '100vh', // 充满整个视口高度
        backgroundColor: '#F6F5F0', // 设置背景颜色
      }}>
     
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {[...articles] // 创建articles的副本
            .sort((a, b) => b.id - a.id) // 按照id降序排列
            .map((article, index) => (
                <li key={article.id} style={{ fontSize: '14px', marginLeft: '10px', lineHeight: `${goldenLineRatioWidth}px` }}>
                <div style={{ cursor: 'pointer' }} onClick={() => handleArticleClick(article.id)}>
                    {`${index + 1}. ${article.title}`}
                </div>
                <p style={{ fontSize: '10px', color: '#888', marginLeft: '15px' }}>
                    created at : {article.created_at} by: {article.author}
                </p>
                <p>{article.content}</p>
                </li>
            ))
            }

      </ul>
    </div>
    <footer
      style={{
        fontSize: '10px',
        color: '#888',
        width: `${goldenRatioWidth}vw`,
        textAlign: 'center', // 文本居中对齐
        backgroundColor: '#F6F5F0', // 设置背景颜色
        borderTop: '1px solid #ffa343', // 添加上边线并设置颜色
        height:'25px',
        paddingTop:'5px'
      }}
    >
      <a href="/login">指南</a> | <a href="/login">FAQ</a> | <a href="/login">加入我们</a> | <a href="/login">联系方式</a>
    </footer>
  </div>
    
  );
}

export default ArticlesListPage;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Quill from 'quill';
import 'quill/dist/quill.bubble.css'; // 导入bubble主题的CSS
import { useNavigate } from 'react-router-dom';
import logo from './logo2.png'; 
import { 
  useTheme,
  useMediaQuery, 
} from '@mui/material';
import { ImageDrop } from 'quill-image-drop-module';


function EditPage() {
  const { articleId } = useParams(); // 假设 URL 参数名为 'articleId'
  const [isSuperuser, setIsSuperuser] = useState(null); // 新增状态
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [group_name, setGroupName] = useState('');
  const quillRef = useRef(null);
  const quillInitialized = useRef(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [goldenRatioWidth, setGoldenRatioWidth] = useState(100 * 0.85);
  Quill.register('modules/imageDrop', ImageDrop);


  useEffect(() => {
    
    setGoldenRatioWidth(isMobile ? 100 : 100 * 0.85);
    const isSuperuser = localStorage.getItem('is_superuser') === 'true'; // 从 localStorage 中读取 is_superuser 标志
    setIsSuperuser(isSuperuser);
    
    if (quillRef.current && !quillInitialized.current) {
      const quill = new Quill(quillRef.current, {
        theme: 'bubble', // 使用bubble主题
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['image', 'code-block'],
          ],
        },
      });

      quill.on('text-change', () => {
        setContent(quill.root.innerHTML);
      });

      quillInitialized.current = true;

      const fetchArticle = async () => {
        const token = localStorage.getItem('token');
        if (!articleId || !token) return;

        try {
        const response = await fetch(`https://wechat.haodaqi.net/api/articles/title/${articleId}/`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setTitle(data.title);
        setGroupName(data.project_name);
        quill.clipboard.dangerouslyPasteHTML(data.content);
        } catch (error) {
        console.error('Error fetching article:', error);
        }
    };

    fetchArticle();

    }
  }, [isMobile]);

    const saveArticle = async () => {
        const token = localStorage.getItem('token');
        const isSuperuser = localStorage.getItem('is_superuser') === 'true';
        setIsSuperuser(isSuperuser);

        // 如果 articleId 存在，则为更新操作，否则为创建新文章
        const apiURL = articleId
            ? `https://wechat.haodaqi.net/api/articles/${articleId}/update/` // 更新文章的 URL
            : isSuperuser
            ? 'https://wechat.haodaqi.net/api/save_article_staff/' // 创建新文章的 URL（超级用户）
            : 'https://wechat.haodaqi.net/api/save_article/';      // 创建新文章的 URL（普通用户）

        const requestBody = JSON.stringify({ title, content, ...(isSuperuser && { group_name }) });

        try {
            const response = await fetch(apiURL, {
            method: 'POST',
            body: requestBody,
            });

            if (response.ok) {
            const result = await response.json();
            console.log(articleId ? 'Article Updated:' : 'Article Created:', result);
            navigate('/dashboard');
            } else {
            const errorData = await response.json();
            console.error('Failed to save article:', errorData);
            }
        } catch (error) {
            console.error('Error submitting article:', error);
        }
        };



  const handleSubmit = async (e) => {
      e.preventDefault();
      await saveArticle(); // 使用 await 确保异步函数执行完毕
  };
  

  const handleNavigation = (path) => {
    navigate(path);
  }

return (
  <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> 
    <div style={{ backgroundColor: '#ffa343', width: `${goldenRatioWidth}vw`, display: 'flex', alignItems: 'center', height: '26px' }}>
      <img src={logo} alt="Logo" width="18px" height="18px" style={{ marginLeft: '3px', border: '1px solid black' }}/> 
      <a href="/" style={{ color: 'black', fontSize: '14px', marginLeft: '5px', fontWeight: 'bold', fontFamily: 'sans-serif', textDecoration: 'none'}}>好端端</a>
    </div>
    <div style={{ marginTop: '0px', width: `${goldenRatioWidth}vw`, backgroundColor: '#F6F5F0' }}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', position: 'relative', marginTop: '10px' }}>
        <label htmlFor="titleInput" style={{ fontSize: '15px', color: '#888', marginLeft: '2px' }}>标题</label>
        <input id="titleInput" value={title} onChange={(e) => setTitle(e.target.value)} style={{ marginLeft: '2px', width: `${goldenRatioWidth * 0.2}vw` }} />
        
        {/* 如果是超级用户，则显示 groupname 输入框 */}
        {isSuperuser && (
          <div style={{ marginLeft: '2px', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
            <label htmlFor="groupNameInput" style={{ fontSize: '15px', color: '#888', marginLeft: '2px' }}>Group Name</label>
            <input id="groupNameInput" value={group_name} onChange={(e) => setGroupName(e.target.value)} style={{ marginLeft: '2px', width: `${goldenRatioWidth * 0.2}vw` }} />
          </div>
        )}

        <div style={{ marginLeft: '2px', display: 'flex', flexDirection: 'column', marginBottom: '10px', width: `${goldenRatioWidth * 0.4}vw` }}>
          <label htmlFor="quillEditor" style={{ fontSize: '15px', color: '#888', marginLeft: '2px' }}>正文</label>
          <div ref={quillRef} id="quillEditor" style={{ flexGrow: 1, height: '300px', background: 'white' }} />
        </div>
        
        <button style={{ marginLeft: '2px', width: '50px' }}>修改</button>
        <p style={{ fontSize: '15px', color: '#888', marginLeft: '2px', marginTop:'10px'}}>使用鼠标选中对应的文本，可以改变文本的格式，加入对应的图片，增加代码块</p>
      </form>
    </div>
  </div>
);
}

export default EditPage;
